import React from "react";
import { BrowserRouter as Router, Routes, Route, NavLink, Outlet } from "react-router-dom";
import MintPage from "./MintPage";
import MainPage from "./MainPage";
import DashboardPage from "./DashboardPage";
import "./style.css";

function App() {
    return (
	<Router>

	    <div className={`top-color`}>
		<div className={`header`}>
		    <div className={`container`}>
			<div className={`header-container`}>
			    <div className={`col`}>
				<div className={`logo`}>
				    <img width="300" src={`logo.svg`} alt="logo" />
				</div>
			    </div>
			    <div className={`col social-media`}>
				<ul>
				    <li>
					<a
					    href={"https://twitter.com/SOLParasitesNFT"}
					    target={`_blank`}
					>
					    <img src={`twitter.svg`} alt="twitter" />
					</a>
				    </li>
				    <li>
					<a
					    href={"https://discord.com/invite/uWxfT8FMs8"}
					    target={`_blank`}
					>
					    <img src={`discord.svg`} alt="discord" />
					</a>
				    </li>
				</ul>
			    </div>
			</div>
		    </div>
		</div>


		<div className="App">
		    <nav>
			<ul className="navigation">
			    <li className="navigation-item">
				<NavLink exact activeClassName="active" to="/">
				    Main
				</NavLink>
			    </li>
			    <li className="navigation-item">
				<NavLink activeClassName="active" to="/mint">
				    Mint
				</NavLink>
			    </li>
			    <li className="navigation-item">
				<NavLink activeClassName="active" to="/dashboard">
				    Dashboard
				</NavLink>
			    </li>
			</ul>
		    </nav>

		    <br/>
		    <br/>
		    <br/>
		    <br/>


		    <Routes>
			<Route path="/" element={<MainPage />} />
			<Route path="/mint" element={<MintPage />} />
			<Route path="/dashboard" element={<DashboardPage />} />
		    </Routes>
		</div>
	    </div>
	    <div className={`footer`}>
		<div className={`container`}>
		    <div className={`footer-block`}>
			<div className={`col`}>
			    <ul>
				<li>
				    <a href="https://raritysniper.com/nft-drops-calendar">NFT Drops</a>
				</li>
			    </ul>
			</div>
			<div className={`col`}>
			    <p>Copyright 2023 by ETHParasites.com</p>
			</div>
		    </div>
		</div>
        </div>

	</Router>

    );
}

export default App;
