import React, { useState, useEffect } from "react";
import Web3 from "web3";
import nftABI from "./EthParasites.json"; // Replace this with the ABI of your NFT contract


const CONTRACT_ADDRESS = "0x26DE913216a87226B6c63C76479fee516240d29a"; // Replace with your NFT contract address
const NETWORK_ID = 1; // Change this to the appropriate network ID (1 for Ethereum Mainnet)
//const NETWORK_ID = 11155111 // Sepolia
//const NETWORK_ID = 1682777231436;


function MintPage() {
    const [web3, setWeb3] = useState(null);
    const [account, setAccount] = useState("");
    const [minting, setMinting] = useState(false);
    const [redemptionCode, setRedemptionCode] = useState("");
    const [countdown, setCountdown] = useState(null);

    const debug = true;
    
    // Calculate the time remaining and format it
    const getTimeRemaining = (endTime) => {
	const total = Date.parse(endTime) - Date.now();
	const seconds = Math.floor((total / 1000) % 60);
	const minutes = Math.floor((total / 1000 / 60) % 60);
	const hours = Math.floor((total / (1000 * 60 * 60)) % 24);
	const days = Math.floor(total / (1000 * 60 * 60 * 24));
	return {
	    total,
	    days,
	    hours,
	    minutes,
	    seconds,
	};
    };

    useEffect(() => {
	const targetDate = new Date("2023-05-22T19:00:00-04:00"); // 8 PM EST, 12 PM UTC
	const timer = setInterval(() => {
	    const remaining = getTimeRemaining(targetDate);
	    if (remaining.total <= 0) {
		clearInterval(timer);
		setCountdown("");
	    } else {
		setCountdown(
		    `${remaining.days} days ${remaining.hours} hours ${remaining.minutes} minutes ${remaining.seconds} seconds`
		);
	    }
	}, 1000);

	return () => clearInterval(timer);
    }, []);


    const connectWallet = async () => {
	if (window.ethereum) {
	    try {
		await window.ethereum.request({ method: "eth_requestAccounts" });
		const web3Instance = new Web3(window.ethereum);
		const accounts = await web3Instance.eth.getAccounts();
		setWeb3(web3Instance);
		setAccount(accounts[0]);
	    } catch (error) {
		console.error("Error connecting wallet:", error);
	    }
	} else {
	    alert("Please install MetaMask or another Web3 provider.");
	}
    };

    const decodeSignature = async (signatureStr) => {
	if (typeof signatureStr !== "string") {
	    throw new Error("Invalid signature string");
	}

	const r = "0x" + signatureStr.slice(0, 64);
	const s = "0x" + signatureStr.slice(64, 128);
	const v = web3.utils.toHex(parseInt(signatureStr.slice(128), 16)); // Convert v to hexadecimal format

	for (let tokenId = 1; tokenId <= 10000; tokenId++) {
	    const messageHash = web3.utils.soliditySha3({ t: "uint256", v: tokenId });
	    const message = web3.eth.accounts.hashMessage(messageHash);
	    const recoveredAddress = web3.eth.accounts.recover({ messageHash: message, v, r, s });
	    const signerAddress = "0xFca25409D1aa085c811188c547549F699eC4c140";
	    if (recoveredAddress.toLowerCase() === signerAddress.toLowerCase()) {
		return { r, s, v, tokenId };
	    }
	}

	throw new Error("Token ID not found");
    };

    const mintPaidNFT = async () => {
	if (!web3) {
	    alert("Please connect your wallet first.");
	    return;
	}

	const nftContract = new web3.eth.Contract(nftABI.abi, CONTRACT_ADDRESS);
	const networkId = await web3.eth.net.getId();

	if (networkId !== NETWORK_ID) {
	    alert("Please switch to the correct network. Current" + networkId);
	    return;
	}

	setMinting(true);

	try {
	    await nftContract.methods
		.mintNFT(account)
		.send({
		    from: account,
		    value: Web3.utils.toWei("0.02", "ether"),
		    gas: 200000
		});
	    alert("NFT minted successfully!");
	} catch (error) {
	    if (error.data) {
		const reason = web3.eth.abi.decodeParameter('string', error.data.slice(10));
		alert(reason);
	    } else {
		alert("An error occurred while minting the NFT. Please try again.");
	    }
	} finally {
	    setMinting(false);
	}
    };


    const mintFreeNFT = async () => {
	if (!web3) {
	    alert("Please connect your wallet first.");
	    return;
	}

	const nftContract = new web3.eth.Contract(nftABI.abi, CONTRACT_ADDRESS);
	const networkId = await web3.eth.net.getId();

	if (networkId !== NETWORK_ID) {
	    alert("Please switch to the correct network. Current" + networkId);
	    return;
	}

	const signatureStr = redemptionCode.trim();
	if (!signatureStr) {
	    alert("Please enter a valid redemption code.");
	    return;
	}
	
	try {
	const { r, s, v, tokenId } = await decodeSignature(redemptionCode.trim());
	
	console.log("Token ID: " + tokenId);

	setMinting(true);


	    const nonce = await web3.eth.getTransactionCount(account);
	    await nftContract.methods
		.mintFreeNFT(account, tokenId, r, s, v)
		.send({ from: account, nonce, gas: 250000 });
	    alert("NFT minted successfully!");
	} catch (error) {
	    console.log(error);
	    if (error.data) {
		const reason = web3.eth.abi.decodeParameter('string', error.data.slice(10));
		alert(reason);
	    } else {
		alert("An error occurred while minting the NFT. Please try again.");
	    }
	} finally {
	    setMinting(false);
	}
    };


 return (
    <div className="App">
      {!debug && countdown !== null && (
        <h2 className="countdown">
          {countdown === ""
            ? "Minting Open!"
            : `Mint opens in: ${countdown}`}
        </h2>
      )}
      {debug && <h2 className="countdown">Minting Open!</h2>}
      {(countdown === "" || debug) && (
        <div className="containerA">
          {!account && (
            <button className="btn" onClick={connectWallet}>
              Connect Wallet
            </button>
          )}
          {account && (
            <>
              <p>Connected Account: {account}</p>
              <button className="btn" onClick={mintPaidNFT} disabled={minting}>
                {minting ? "Minting..." : "Mint NFT (0.02 ETH)"}
              </button>
              <br />
              Mint with SOL Parasites Redemption Code:
              <br />
              <div className="input-containerA">
                <input
                  type="text"
                  value={redemptionCode}
                  onChange={(e) => setRedemptionCode(e.target.value)}
                  placeholder="Enter redemption code"
                />
                <button className="btn" onClick={mintFreeNFT} disabled={minting}>
                  {minting ? "Minting..." : "Mint NFT (Free)"}
                </button>
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
}    


    
export default MintPage;
