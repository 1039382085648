import React from "react";
import "./style.css";

function MainPage() {
    return <div>
	       <div className="banner-bottom">
		   <img className="half-width-image" src="ethparasites.jpg" alt="banner" />
	       </div>
	       <div style={{ textAlign: 'center', fontSize: '1.5em', fontWeight: 'bold', marginTop: '1em' }}>
		   Unleash the mutating power of Parasite NFTs, where captivating art evolves daily.<br />
		   Join the revolution and embrace the intrigue of a truly unique NFT experience!
	       </div>
	       <div className={`road-map`}>
		   <div className={`title`}>
		       <h2>Road Map</h2>
		   </div>

		   <div className={`container`}>
		       <div className="timeline">
			   <div className="n-container left">
			       <div className="content">
				   <h2>Solana Chain Infection</h2>
				   Parasites began invading the Solana chain on November 5th, 2021.
			       </div>
			   </div>
			   <div className="n-container right">
			       <div className="content">
				   <h2>ETH Crossover</h2>
				   After more than 1.5 years and 4 million mutations, Parasites crossed the chain barrier to the Ethereum chain.
			       </div>
			   </div>
			   <div className="n-container left">
			       <div className="content">
				   <h2>ETH Parasites Mint</h2>
				   The minting event for ETH Parasites is scheduled for May 22nd, 2023, 8:00 PM Eastern Standard Time (EST) or 12 PM UTC.
			       </div>
			   </div>
			   <div className="n-container right">
			       <div className="content">
				   <h2>Mutation Beginnings</h2>
				   New Parasites will start mutating approximately one week after the mint.
			       </div>
			   </div>
			   <div className="n-container left">
			       <div className="content">
				   <h2>Holder Dashboard Launch</h2>
				   The Holder Dashboard will become available for tracking NFT statuses and actions.
			       </div>
			   </div>
			   <div className="n-container right">
			       <div className="content">
				   <h2>Secondary Markets</h2>
				   ETH Parasites will be available for trading on OpenSea and other major secondary marketplaces.
			       </div>
			   </div>
			   <div className="n-container left">
			       <div className="content">
				   <h2>NFT Genie Exclusive Access</h2>
				   NFT Genie, the unique AI system for creating NFT memes and fan art, is exclusively available to Parasite holders. It is already live for SOL Parasite holders and will open for ETH access on May 28th, 2023.
			       </div>
			   </div>
			   <div className="n-container right">
			       <div className="content">
				   <h2>Future Updates</h2>
				   Stay tuned for more exciting developments by joining our Discord community.
			       </div>
			   </div>
		       </div>
		   </div>

		   
		   
               </div>


               <div className={`faq`}>
		   <div className={`title`}>
		       <h2>Frequently Asked Questions</h2>
		   </div>
		   <div className={`container`}>
		       <div className="wrapper">
			   <ul>
			       <li>
				   <input type="checkbox" id="list-item-1" />
				   <label htmlFor="list-item-1">What is the project about?</label>
				   <ul>
				       <li>The project involves unique Parasite NFTs that mutate daily based on other NFTs in the same wallet, creating diverse and intriguing visual changes.</li>
				   </ul>
			       </li>
			       <li>
				   <input type="checkbox" id="list-item-2" />
				   <label htmlFor="list-item-2">What utility do I get?</label>
				   <ul>
				       <li>NFT holders receive exclusive access to the NFT Genie AI system for creating NFT memes and fan art, as well as the ability to freeze or unfreeze their Parasite's mutations on the dashboard.</li>
				   </ul>
			       </li>
			       <li>
				   <input type="checkbox" id="list-item-4" />
				   <label htmlFor="list-item-4">Which wallet do I need?</label>
				   <ul>
				       <li>The mint is on Ethereum, and we recommend using MetaMask, although any modern Ethereum wallet should work.</li>
				   </ul>
			       </li>
			       <li>
				   <input type="checkbox" id="list-item-5" />
				   <label htmlFor="list-item-5">What is the mint price?</label>
				   <ul>
				       <li>The mint price is set at 0.02 ETH.</li>
				   </ul>
			       </li>
			       <li>
				   <input type="checkbox" id="list-item-6" />
				   <label htmlFor="list-item-6">What is a mutation?</label>
				   <ul>
				       <li>A mutation is a visual change in the Parasite NFT based on other NFTs present in the same wallet, resulting in unique and evolving artworks. Mutations occur daily unless you choose to freeze them.</li>
				   </ul>
			       </li>
			       <li>
				   <input type="checkbox" id="list-item-3" />
				   <label htmlFor="list-item-3">Need help or have more questions?</label>
				   <ul>
				       <li>Join our Discord server and use the @plasmodium bot in the #general channel to ask any questions, and we will provide answers immediately. Guaranteed.</li>
				   </ul>
			       </li>
			       <br />
			       <br />
			       <br />
			       <br />
			       <br />
			       <br />
			       <br />
			       <br />
			   </ul>
		       </div>
		   </div>
               </div>

	       
	   </div>;

}

export default MainPage;
